import React from "react";
import { graphql, Link } from "gatsby";

import SEO from "../../components/seo";
import PortableText from "../../components/portableText";
import CycleImage from "../../components/cycle-image";

import explicit from "../../images/hate-symbol-examples/explicit.webp";
import contextual from "../../images/hate-symbol-examples/contextual.webp";
import characters from "../../images/hate-symbol-examples/characters.webp";
import contemporary from "../../images/hate-symbol-examples/contemporary.webp";
import historical from "../../images/hate-symbol-examples/historical.webp";
import allSymbols from "../../images/hate-symbol-examples/all.webp";
import SearchUI from "../../components/search/SearchUI";

export const query = graphql`
  query SymbolsPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      _rawSymbolsPageContent(resolveReferences: { maxDepth: 5 })
      publicSymbolsExplicitDescription
      publicSymbolsContextualDescription
      publicSymbolsCharactersDescription
      publicSymbolsContemporaryDescription
      publicSymbolsHistoricalDescription
      _rawSymbolsPageConclusion(resolveReferences: { maxDepth: 5 })
    }
  }
`;

const SymbolsPage = ({ data = {} }) => {
  const introduction = data.site?._rawSymbolsPageContent;
  const {
    publicSymbolsExplicitDescription,
    publicSymbolsContextualDescription,
    publicSymbolsCharactersDescription,
    publicSymbolsContemporaryDescription,
    publicSymbolsHistoricalDescription,
  } = data.site ?? {};
  const conclusion = data.site?._rawSymbolsPageConclusion;
  return (
    <>
      <SEO title="Guide to Online Hate" />
      {introduction && (
        <div className="prose dark:prose-invert prose-lg md:pt-12 md:pb-7 public-symbols-page-content">
          <PortableText blocks={introduction} />
        </div>
      )}
      <div className="pb-7">
        <SearchUI />
      </div>
      <ul className="link-grid condensed grid gap-x-8 gap-y-12 w-full mb-16 mt-12 md:mt-0">
        <li className="mx-auto md:mx-0 max-w-[380px] md:max-w-none">
          <Link to="./explicit" className="h-full flex flex-col">
            <CycleImage
              src={explicit}
              className="w-full aspect-square object-cover bg-red-900 crisp"
            />
            <div className="pt-3 pb-2 font-sans font-bold text-4xl uppercase text-gray-900 dark:text-gray-100">
              Explicit Hate
            </div>
            <div className="text-lg text-gray-700 dark:text-gray-300">
              {publicSymbolsExplicitDescription}
            </div>
          </Link>
        </li>
        <li className="mx-auto md:mx-0 max-w-[380px] md:max-w-none">
          <Link to="./contextual" className="h-full flex flex-col">
            <CycleImage
              src={contextual}
              className="w-full aspect-square object-cover bg-red-900 crisp"
            />
            <div className="pt-3 pb-2 font-sans font-bold text-4xl uppercase text-gray-900 dark:text-gray-100">
              Contextual Hate
            </div>
            <div className="text-lg text-gray-700 dark:text-gray-300">
              {publicSymbolsContextualDescription}
            </div>
          </Link>
        </li>
        <li className="mx-auto md:mx-0 max-w-[380px] md:max-w-none">
          <Link to="./characters" className="h-full flex flex-col">
            <CycleImage
              src={characters}
              className="w-full aspect-square object-cover bg-red-900 crisp"
            />
            <div className="pt-3 pb-2 font-sans font-bold text-4xl uppercase text-gray-900 dark:text-gray-100">
              Internet Memes with Hateful Uses
            </div>
            <div className="text-lg text-gray-700 dark:text-gray-300">
              {publicSymbolsCharactersDescription}
            </div>
          </Link>
        </li>
        <li className="mx-auto md:mx-0 max-w-[380px] md:max-w-none">
          <Link to="./contemporary" className="h-full flex flex-col">
            <CycleImage
              src={contemporary}
              className="w-full aspect-square object-cover bg-red-900 crisp"
            />
            <div className="pt-3 pb-2 font-sans font-bold text-4xl uppercase text-gray-900 dark:text-gray-100">
              Contemporary Canadian Hate Groups
            </div>
            <div className="text-lg text-gray-700 dark:text-gray-300">
              {publicSymbolsContemporaryDescription}
            </div>
          </Link>
        </li>
        <li className="mx-auto md:mx-0 max-w-[380px] md:max-w-none">
          <Link to="./historical" className="h-full flex flex-col">
            <CycleImage
              src={historical}
              className="w-full aspect-square object-cover bg-red-900 crisp"
            />
            <div className="pt-3 pb-2 font-sans font-bold text-4xl uppercase text-gray-900 dark:text-gray-100">
              Historical Canadian Hate Groups
            </div>
            <div className="text-lg text-gray-700 dark:text-gray-300">
              {publicSymbolsHistoricalDescription}
            </div>
          </Link>
        </li>
        <li className="mx-auto md:mx-0 max-w-[380px] md:max-w-none">
          <Link to="./all" className="h-full flex flex-col">
            <CycleImage
              src={allSymbols}
              className="w-full aspect-square object-cover bg-red-900 crisp"
            />
            <div className="pt-3 pb-2 font-sans font-bold text-4xl uppercase text-gray-900 dark:text-gray-100">
              Full list
            </div>
            <div className="text-lg text-gray-700 dark:text-gray-300">
              All hate symbols, terms, and themes from all five categories on a
              single page.
            </div>
          </Link>
        </li>
      </ul>
      {conclusion && (
        <div className="prose dark:prose-invert prose-lg pt-8 pb-16 md:pt-16 italic text-center md:text-left">
          <PortableText blocks={conclusion} />
        </div>
      )}
    </>
  );
};

export default SymbolsPage;
